
import React from "react";

import { ApexOptions } from "apexcharts";

import { CHART_COLORS } from "@/constants";
import { commaSeperatorFormat } from "@/helpers/common";
import useCheckLargeDesktopScreen from "@/hooks/useCheckLargeDesktopScreen";
import ApexChart from "@/shared/components/charts/ApexChart";

import styles from "../../../assets/styles/components/chart.module.scss";

interface IAreaLineChart {
    chartData: Homepage.Section42;
    height: number;
    settings?: any
}
function AreaLineChart({ chartData, height, settings }: IAreaLineChart) {
    const isLargeDesktopScreen = useCheckLargeDesktopScreen();

    const { data } = chartData;
    let defaultSeries: Array<number> = [];
    if (data && data.length) {
        defaultSeries = data.map((value: any) => value);
    }

    const defaultOptions: ApexOptions = {
        chart: {
            height: 200,
            type: "line",
            id: "linechart",
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            curve: "straight",
            width: 4,
        },
        grid: {
            show: false,

        },
        colors: [CHART_COLORS.TEAL, CHART_COLORS.PURPLE],
        markers: {
            // size: [6, 7, 8],
            shape: "circle",
        },
        yaxis: [
            {
                title: {
                    text: "No. of female-led companies",
                },
                labels: {
                    style: {
                        fontWeight: "bold",
                    },
                    formatter(val) {
                        return commaSeperatorFormat(val);
                    },
                },
            },
        ],
        xaxis: {
            type: chartData?.xAxis?.type ? chartData?.xAxis?.type as any : "category",
            categories: chartData?.xAxis?.categories ? chartData?.xAxis?.categories : null,
            title: {
                text: "Month",
            },
            labels: {
                style: {
                    fontWeight: 700,
                },
            },
        },
        responsive: [{
            breakpoint: 1000,
            options: {
                xaxis:{
                    tickAmount:4,
                },
            },
        }],
        fill: {
            type: ["gradient"],
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.25,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [0, 100],
            },
        },
        title: {
            text: undefined,
        },
        legend: {
            show: true,
            onItemClick: {
                toggleDataSeries: false,
            },
            // position: "bottom",
            // horizontalAlign: "center",
            // floating: true,
            // offsetY: -10,
            // offsetX: 0,
        },
    };
    const config = {
        ...defaultOptions,
        ...settings,
    };

    return (
        <div className={styles["area-line"]} >
            <ApexChart
                options={config}
                series={defaultSeries}
                type="line"
                height={isLargeDesktopScreen ? "700px" : height} />
        </div >
    );
}

export default AreaLineChart;
