import React from "react";

import styles from "@/assets/styles/pages/home.module.scss";

export interface ICircleData{
    name: string;
    value: number | string;
    background: string
}

interface ICircle {
    data: ICircleData[]
    title?: string
}
function CircleWithText({ data, title }: ICircle) {
    return (
        <div className={"grid-wrapper"}>
            <div className="container">
                <div className={styles["secured-debt-circle-title"]}>
                    {title}
                </div>
                <div className="circle-wrapper">
                    {
                        data.map(i => (
                            <>
                                <div className="circle-container">
                                    <div className="circle" style={{
                                        background: i.background,
                                        border: i.background,
                                    }}>
                                        {i.value}
                                    </div>
                                    <div className="circle-text">
                                        {i.name}
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default CircleWithText;
