/* eslint-disable camelcase */
import React, { useEffect } from "react";

import { faLocationDot, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/dist/client/link";

import styles from "@/assets/styles/pages/home.module.scss";
import MapContainer from "@/features/explore/maps/MapContainer";
import { genderData } from "@/helpers/filter-data/demographics";
import { locationDataByKey } from "@/helpers/filter-data/location";
import { useFilters } from "@/providers/FiltersProvider";
import SectionTitle from "@/shared/components/section-title/SectionTitle";
import SummaryDataGrid, { ISummaryDataGridHeader } from "@/shared/components/SummaryDataGrid";

interface ITheNationalPicture {
    data: Homepage.NationalPicture;
}
function TheNationalPicture({ data }: ITheNationalPicture) {
    const { setMainFilters } = useFilters();
    const summary = data?.["section2.1"];

    const header: ISummaryDataGridHeader[] = [
        {
            id: "countryName",
            heading: "Nation",
            ratio: 4,
        },
        {
            id: "percentageFemaleLedLast12Months",
            heading: "% female-led*",
            formatType: "percentage",
            ratio: 4,
        },
        {
            id: "percentageChange",
            heading: "% change**",
            // subHeading: "(Previous 12 months)",
            formatType: "trend",
            ratio: 4,
            trendKey: "percentageChange",
        },
    ];
    const tableData = data?.["section2.2"] || [];

    const regionData = tableData.map(i => ({
        id: i.idCountry,
        name: i.countryName,
        code: i.countryCode,
        gender_percentage: i.percentageFemaleLedLast12Months,
    }));

    useEffect(() => {

        const data = {
            "D001": {
                data: genderData.filter(i => i.label.toLowerCase() === "female"),
            },
            "LL001": {
                data: locationDataByKey.ukCountries,
                all: true,
            },
        };
        setMainFilters(data);

        () => {
            setMainFilters(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles["national-price"]}>
            <div className="container">
                <div className={`${styles["national-price-main"]} grid-wrapper`}>
                    <div className={`row ${styles["national-price-main-row"]}`}>
                        <div className="col-12 col-xl-6">
                            <SectionTitle
                                iconProps={{
                                    icon: faLocationDot,
                                    background: "#FD7278",
                                }}
                                title={"The national picture"}
                                summary={summary}
                            />
                            <div className={`${styles["national-price-main-sdg"]}`}>
                                <SummaryDataGrid
                                    data={tableData}
                                    header={header} />
                                <div className={styles["national-price-main-sdg-label-cont"]}>
                                    <div className={styles["national-price-main-active-uk"]}>*Active UK companies</div>
                                    <div className={styles["national-price-main-active-uk"]}>**previous 12 months</div>
                                </div>
                                <Link
                                    href={"/explore"}
                                    className={styles["national-price-main-explore-link"]}>Explore the data <FontAwesomeIcon icon={faUpRightFromSquare} /></Link>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <MapContainer
                                regionData={{
                                    data: regionData,
                                    isLoading: false,
                                }}
                                disableExpand
                                disableZoom
                                disableInfo
                                percentage
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TheNationalPicture;
