import React from "react";

import Link from "next/link";
import { TinaMarkdown } from "tinacms/dist/rich-text";

import styles from "@/assets/styles/pages/home.module.scss";

interface IUpcomingEvents {
    eventsData: any
}
function UpcomingEvents({ eventsData }: IUpcomingEvents) {
    const data = eventsData?.data?.eventsConnection?.edges?.[0].node;

    if (!data || !data?.date) {
        return null;
    }
    return (
        <div className={styles["upcoming-events"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className={styles["upcoming-events-container"]}>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">

                                <div className={styles["upcoming-events-heading"]}>
                                    Upcoming events
                                </div>
                                <p className={styles["upcoming-events-sub-heading"]}>{data?.title}</p>

                                <p className={styles["upcoming-events-date-time"]}>{data?.location} &#124; {data?.date}</p>
                                <div className={styles["upcoming-events-summary"]} >
                                    <TinaMarkdown content={data.description} />
                                </div>
                                <Link href={"/contact"}>
                                    <button
                                        className={`${styles["upcoming-events-button"]} btn-red-transparent`}>
                                        Get in touch
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpcomingEvents;
