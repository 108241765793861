import React from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "@/assets/styles/components/section-icon.module.scss";

interface ISectionIcon {
    icon: IconProp;
    background: string;
    width?: number | string | undefined;
    height?: number | string | undefined;
    fontSize?: number | string | undefined;
    className?: string;
}
function SectionIcon({ background, icon, height, width, fontSize, className }: ISectionIcon) {
    return (
        <div className={`${styles["item"]}${className ? ` ${className}` : ""}`}
            style={{
                background,
                width,
                height,
                fontSize,
            }}>
            <FontAwesomeIcon icon={icon} style={{ fontSize }} />
        </div>
    );
}

export default SectionIcon;
