import React from "react";

import { faHandHoldingSeedling } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "@/assets/styles/pages/home.module.scss";
import { formatNumberCompact } from "@/helpers/common";
import SectionTitle from "@/shared/components/section-title/SectionTitle";
import SummaryDataGrid, { ISummaryDataGridHeader } from "@/shared/components/SummaryDataGrid";

interface IInvestmentLedCompany {
    data: Homepage.Investment
}
function InvestmentLedCompany({ data }: IInvestmentLedCompany) {
    const summary = data?.["section5.1"];
    const capitalRaised = data?.["section5.2"];
    const rowData = data["section5.3"];

    const header: ISummaryDataGridHeader[] = [
        {
            id: "investmentType",
            heading: "Investment type",
            ratio: 4,
        },
        {
            id: "count",
            heading: "No. of investments*",
            // subHeading: "(Last 12 months)",
            formatType: "number",
            ratio: 4,
        },
        {
            id: "perc",
            heading: "Percentage**",
            // subHeading: "(Previous 12 months)",
            formatType: "percentage",
            ratio: 4,
            // trendKey: "perc",
        },
    ];

    return (
        <div className={styles["investment-led-company"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-xl-12">
                            <SectionTitle
                                iconProps={{
                                    icon: faHandHoldingSeedling,
                                    background: "#FFC000",
                                }}
                                title={"Investment into active female-led companies"}
                                summary={summary}
                            />
                        </div>
                    </div>
                    <div className={styles["investment-led-company-chart-cont"]}>
                        <div className="row">
                            {/* <div className="col-12 col-lg-4 col-xl-4">
                                <div className={styles["investment-led-company-chart-cont-tcr"]}>
                                    <div>
                                        <FontAwesomeIcon icon={faHandHoldingSeedling} />
                                    </div>
                                    <div className={styles["investment-led-company-chart-cont-tcr-text"]}>
                                        Total Investments:
                                    </div>
                                    <div className={styles["investment-led-company-chart-cont-tcr-amount"]}>
                                        {formatNumberCompact(capitalRaised.count)}
                                    </div>
                                    <div className={styles["investment-led-company-chart-cont-tcr-text"]}>
                                        in last 12 months
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-12 col-lg-12 col-xl-12">
                                <SummaryDataGrid
                                    data={rowData}
                                    header={header}
                                    background="#FD7278"
                                    spacing="small"
                                />
                                <div className={styles["investment-led-company-chart-cont-info"]}>
                                    <span>*Last 12 months</span>
                                    <span>**of all investments</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvestmentLedCompany;
