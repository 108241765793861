import React from "react";

import { faChartColumn } from "@fortawesome/free-solid-svg-icons";

import styles from "@/assets/styles/pages/home.module.scss";
import AreaLineChart from "@/shared/components/charts/AreaLineChart";
import SectionTitle from "@/shared/components/section-title/SectionTitle";

interface IFemaleIncorporation {
    data: Homepage.FemaleInc
}

function FemaleIncorporation({ data }: IFemaleIncorporation) {
    const summary = data?.["section3.1"];
    const chartData = data?.["section3.2"].data;

    return (
        <div className={styles["female-incorporation"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-xl-12">
                            <SectionTitle
                                iconProps={{
                                    icon: faChartColumn,
                                    background: "#012348",
                                }}
                                title={"Female incorporations vs closures over the last 12 months"}
                                summary={summary}
                            />
                        </div>
                    </div>
                    <div className={styles["female-incorporation-chart"]}>
                        <AreaLineChart
                            chartData={chartData}
                            height={400} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FemaleIncorporation;
