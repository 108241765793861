import React, { useCallback } from "react";

import { faDownLong, faEarthAmerica, faUpLong, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

import styles from "@/assets/styles/pages/home.module.scss";
import { formatAsPercentage } from "@/helpers/common";
import { ethnicityData } from "@/helpers/filter-data/demographics";
import { FILTER_KEYS, getInitialMainFilter } from "@/helpers/filters";
import { useFilters } from "@/providers/FiltersProvider";
import SectionTitle from "@/shared/components/section-title/SectionTitle";
interface IEthnicityUkData {
    data: Homepage.EthnicityUk
}
function EthnicityUk({ data }: IEthnicityUkData) {
    const { setRedirectFilters } = useFilters();

    const onClick = useCallback(() => {
        setRedirectFilters(
            {
                ...getInitialMainFilter(),
                [FILTER_KEYS.Ethnicity]:
                {
                    data: [ethnicityData[0]],
                },
            }
        );
    }, []);

    return (
        <div className={styles["ethnicity-uk"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row column-reverse">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                            <div className={styles["ethnicity-uk-stat"]}>
                                <div className={styles["ethnicity-uk-text"]}>
                                    {data?.["section6.1"]?.perc !== 0
                                        ? <FontAwesomeIcon icon={data?.["section6.1"]?.count > 0 ? faUpLong : faDownLong} color="#00A7B7" className={styles["ethnicity-uk-arrow"]} />
                                        : ""}
                                    {formatAsPercentage(data["section6.1"].perc)}
                                </div>
                                {/* <div className={styles["ethnicity-uk-divider-red"]}></div> */}
                                <div className={styles["ethnicity-uk-summary"]}>
                                    Increase in the number of active ethnic minority female-led companies across the UK over the last 12 months
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                            <SectionTitle
                                iconProps={{
                                    icon: faEarthAmerica,
                                    background: "#8B57F6",
                                }}
                                title={"Ethnicity across the UK"}
                                summary={data["section6.2"]}
                            />
                            <div className="mt24">
                                <Link
                                    href={"/explore"}
                                    className="explore-link"
                                    onClick={onClick}>Explore the data <FontAwesomeIcon icon={faUpRightFromSquare} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EthnicityUk;
