import React from "react";

import { GetStaticProps } from "next";
import { useTina } from "tinacms/dist/react";

import { API_ROUTES } from "@/constants";
import DelveIntoData from "@/features/homepage/DelveIntoData";
import EthnicityUk from "@/features/homepage/EthnicityUk";
import FemaleIncorporation from "@/features/homepage/FemaleIncorporation";
import FemaleLedSector from "@/features/homepage/FemaleLedSector";
import GenerationInternationalTrade from "@/features/homepage/GenerationInternationalTrade";
import GenerationShift from "@/features/homepage/GenerationShift";
import HeroSection from "@/features/homepage/HeroSection";
import InvestmentLedCompany from "@/features/homepage/InvestmentLedCompany";
import LatestNewsOrStories from "@/features/homepage/latest-news/LatestNewsOrStories";
import Partners from "@/features/homepage/Partners";
import SecuredDebt from "@/features/homepage/SecuredDebt";
import TheNationalPicture from "@/features/homepage/TheNationalPicture";
import UkStorySection from "@/features/homepage/UkStorySection";
import UpcomingEvents from "@/features/homepage/UpcomingEvents";
import WhatIsGenderIndex from "@/features/homepage/WhatIsGenderIndex";
import { ApiService } from "@/services/ApiService";
import Layout from "@/shared/components/layout";
import PageSeo from "@/shared/components/PageSeo";

import { client } from "../../.tina/__generated__/client";

interface IHome {
    hero: any;
    news: any;
    pageData: Homepage.IDocumentRes;
    events: any;
    stories: any;
}
export default function Home(props: IHome) {
    const mainData = useTina({
        query: props.hero.query,
        variables: props.hero.variables,
        data: props.hero.data,
    });

    const newsData = useTina({
        query: props.news.query,
        variables: props.news.variables,
        data: props.news.data,
    });

    const eventsData = useTina({
        query: props.events.query,
        variables: props.events.variables,
        data: props.events.data,
    });

    const storiesData = useTina({
        query: props.stories.query,
        variables: props.stories.variables,
        data: props.stories.data,
    });

    const document = props?.pageData?.document;

    return (
        <Layout allowContainer={false}>
            <PageSeo title="The Gender Index" />
            <HeroSection mainData={mainData} data={document?.ukStory} />
            <WhatIsGenderIndex />
            <UkStorySection data={document?.ukStory} />
            <TheNationalPicture data={document?.nationalPicture} />
            <FemaleIncorporation data={document?.femaleInc} />
            <GenerationShift data={document?.generationalShift} />
            <InvestmentLedCompany data={document?.investment} />
            <EthnicityUk data={document?.ethnicityUk} />
            <FemaleLedSector data={document?.sectorAnalysis} />
            <GenerationInternationalTrade data={document?.importExportByGender} />
            <SecuredDebt data={document?.secureDebt} />
            <DelveIntoData />
            <LatestNewsOrStories newsData={newsData} storiesData={storiesData} />
            <Partners />
            <UpcomingEvents eventsData={eventsData} />
        </Layout>
    );
}
export const getStaticProps: GetStaticProps<IHome> = async () => {
    const allRequestPromises = [
        client.queries.page({
            relativePath: "home.mdx",
        }),
        client.queries.newsConnection({
            sort: "date_published",
            last: 3,
        }),
        ApiService.get(`${API_ROUTES.GET_HOMEPAGE_DATA}`),
        client.queries.eventsConnection({
            sort: "date_published",
            last: 3,
        }),
        client.queries.storiesConnection({
            sort: "date_published",
            last: 3,
        }),
    ];
    const responses = await Promise.allSettled<any>(allRequestPromises);

    const [hero, news, pageData, events, stories] = responses;

    return {
        props: {
            hero: {
                ...(hero.status === "fulfilled" ? hero.value : {}),
                enableVisualEditing: process.env.VERCEL_ENV === "preview",
            },
            news: news.status === "fulfilled" ? news.value : null,
            pageData: pageData.status === "fulfilled" ? pageData.value.data?.data : null,
            events: events.status === "fulfilled" ? events.value : null,
            stories: stories.status === "fulfilled" ? stories.value : null,
        },
    };
};
