import React, { useCallback } from "react";

import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

import { useFilters } from "@/providers/FiltersProvider";
import SectionIcon from "@/shared/components/SectionIcon";

import styles from "./section-title.module.scss";

interface ISectionTitle {
    iconProps: {
        icon: any;
        background: any;
        width?: number | string | undefined;
        height?: number | string | undefined;
        fontSize?: number | string | undefined;
    };
    title: string | React.ReactNode;
    summary?: string | React.ReactNode;
    explore?: boolean;
    exploreTab?: string
    cb?: any;
}
function SectionTitle({ iconProps: { background, icon }, title, summary, explore, exploreTab, cb }: ISectionTitle) {
    const { setSelectedTab, setFrom } = useFilters();
    const onClick = useCallback(() => {
        if (exploreTab) {
            setSelectedTab(exploreTab);
            setFrom(exploreTab);
        }
        if (cb) {
            cb();
        }
    }, [cb, exploreTab, setFrom, setSelectedTab]);

    return (
        <div className={styles["main"]}>
            <div className={styles.header}>
                <SectionIcon background={background} icon={icon} className={styles["section-icon"]} />
                <div className={styles["header-title"]}>{title}</div>
            </div>
            {
                summary
                && <div className={styles["summary"]}>
                    <p>
                        <strong>AI Summary: </strong>
                        {summary}
                    </p>
                </div>
            }
            {
                explore
                && <div className={styles["explore-wrap"]}>
                    <Link
                        href={"/explore"}
                        onClick={onClick}
                        className={styles["explore-wrap-link"]}>Explore the data <FontAwesomeIcon icon={faUpRightFromSquare} /></Link>
                </div>
            }
        </div>
    );
}

export default SectionTitle;
