import React, { useCallback } from "react";

import { faSwap } from "@fortawesome/pro-solid-svg-icons";

import styles from "@/assets/styles/pages/home.module.scss";
import { exporterData, importerData } from "@/helpers/filter-data/growth";
import { FILTER_KEYS, getInitialMainFilter } from "@/helpers/filters";
import { useFilters } from "@/providers/FiltersProvider";
import SectionTitle from "@/shared/components/section-title/SectionTitle";
import SingleBarChart from "@/shared/components/single-bar-chart/SingleBarChart";

interface IGenerationInternationalTrade {
    data: Homepage.ImportExportByGender
}
function GenerationInternationalTrade({ data }: IGenerationInternationalTrade) {
    const summary = data?.["section8.1"];
    const _importerData = data?.["section8.2"];
    const _exporterData = data?.["section8.3"];

    const { setRedirectFilters } = useFilters();
    const onClick = useCallback(() => {
        setRedirectFilters(
            {
                ...getInitialMainFilter(),
                [FILTER_KEYS.Importers]:
                {
                    data: [importerData[0]],
                },
                [FILTER_KEYS.Exporters]:
                {
                    data: [exporterData[0]],
                },
            }
        );
    }, [setRedirectFilters]);

    return (
        <div className={styles["generation-international-trade"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-xl-5 col-lg-6">
                            <SingleBarChart
                                title="Active importers in the UK"
                                data={{
                                    key1: _importerData.femaleLed,
                                    key2: _importerData.nonFemaleLed,
                                }}
                                labels={{
                                    label1: "Female-led",
                                    label2: "other genders",

                                }}
                            />
                            <div className={styles["generation-international-trade-chart-break"]} />
                            <SingleBarChart
                                title="Active exporters in the UK"
                                data={{
                                    key1: _exporterData.femaleLed,
                                    key2: _exporterData.nonFemaleLed,
                                }}
                                labels={{
                                    label1: "Female-led",
                                    label2: "other genders",
                                }} />
                        </div>
                        <div className="offset-xl-1 offset-0" />
                        <div className="col-12 col-lg-6">
                            <SectionTitle
                                iconProps={{
                                    icon: faSwap,
                                    background: "#199E4E",
                                }}
                                title={"International trade"}
                                summary={summary}
                                explore
                                cb={onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerationInternationalTrade;
