import React from "react";

import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

import { formatDate, getOptimizedImage } from "@/helpers/common";

import styles from "./latest-news-item.module.scss";

interface ILatestNewsOrStoriesItem {
    data: any
}
function LatestNewsOrStoriesItem({ data }: ILatestNewsOrStoriesItem) {
    const path = `/${data.type}/${data?._sys.filename}`;

    return (
        <div
            className={styles["section"]}
            style={{
                background: `linear-gradient(180deg, #1A274400 15%, #1A2744 67%), url('${getOptimizedImage(data.heroImage)}') center center no-repeat`,
                backgroundSize: "cover",
            }}>
            <div className={styles["section-top"]}>
                <div className={styles["section-date"]}>{formatDate(data.date_published, "dd LLL yyyy")}</div>
                <div className={styles["section-title"]}>{data.title}</div>
            </div>
            <Link href={path} className={styles["section-read-more"]} target={"_blank"}>Read more <FontAwesomeIcon icon={faArrowRightLong} /></Link>
        </div>
    );
}

export default LatestNewsOrStoriesItem;
