import React from "react";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./single-bar-chart.module.scss";

interface ISingleBarChart {
    title: string;
    data: {
        key1: number;
        key2: number;
    }
    labels: {
        label1: string;
        label2: string;
    }
}
function SingleBarChart({ title, data, labels }: ISingleBarChart) {
    return (
        <div className={styles["cont"]}>
            <div className={styles["cont-title"]}>{title}</div>
            <div className={styles["cont-bar"]}>
                <div
                    className={`${styles["cont-bar-item"]} ${styles["cont-bar-left"]}`}
                    style={{ width: `${data.key1.toFixed(2)}%` }}>&nbsp;</div>
                <div
                    className={`${styles["cont-bar-item"]} ${styles["cont-bar-right"]}`}
                    style={{ width: `${data.key2.toFixed(2)}%` }}>&nbsp;</div>
            </div>
            <div className={styles["cont-labels"]}>
                <div
                    className={`${styles["cont-labels-item"]} ${styles["cont-labels-left"]}`}>
                    <FontAwesomeIcon icon={faCircle} />
                    <span>{`${data.key1.toFixed(2)}% ${labels.label1}`}</span>
                </div>
                <div className={`${styles["cont-labels-item"]} ${styles["cont-labels-right"]}`}>
                    <FontAwesomeIcon icon={faCircle} />
                    <span>{`${data.key2.toFixed(2)}% ${labels.label2}`}</span>
                </div>
            </div>
        </div>
    );
}

export default SingleBarChart;
