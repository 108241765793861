import React from "react";

import Link from "next/link";

import styles from "@/assets/styles/pages/home.module.scss";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import Button from "@/shared/components/Button";
import { PostPageNameEnum } from "@/types/posts";

import LatestNewsOrStoriesItem from "./LatestNewsOrStoriesItem";

interface ILatestNews {
    newsData: any;
    storiesData: any;
}
function LatestNewsOrStories({ newsData, storiesData }: ILatestNews) {
    const isMobile = useCheckMobileScreen();
    const summary = "Explore the latest news on the Gender Index, roadshows, blogs, and featured stories from our TGI guests and sponsors.";

    const newsList = newsData?.data?.newsConnection?.edges.map((i: any) => ({
        ...i,
        node: {
            ...i.node,
            type: PostPageNameEnum.news,
        },
    })) || [];
    const storiesList = storiesData?.data?.storiesConnection?.edges.map((i: any) => ({
        ...i,
        node: {
            ...i.node,
            type: PostPageNameEnum.stories,
        },
    })) || [];

    const mixedList = [...newsList, ...storiesList].sort((a, b) => new Date(b.node.date_published).getTime() - new Date(a.node.date_published).getTime()).slice(0, 3);

    return (
        <div className={styles["latest-news"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                            <div className={styles["latest-news-left"]}>
                                <div className={styles["latest-news-left-title"]}>
                                    Latest news
                                </div>
                                <div className={styles["latest-news-left-summary"]}>
                                    {summary}
                                </div>
                                <Link href={"/news"}>
                                    <Button variant="outlined">View All</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                            <div className="row">
                                {
                                    mixedList.slice(0, isMobile ? 1 : newsData.length).map((post: any) => (
                                        <div className="col-12 col-lg-4 news-item" key={post.node.id}>
                                            <LatestNewsOrStoriesItem
                                                data={post.node}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LatestNewsOrStories;
