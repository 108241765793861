import React, { useCallback } from "react";

import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatCurrencyForInsight, formatNumberCompact } from "@/helpers/common";

import styles from "../../assets/styles/components/summary-data-grid.module.scss";

type FormatType = "percentage" | "trend" | "currency" | "number";
export interface ISummaryDataGridHeader {
    id: string;
    heading: string | React.ReactNode;
    subHeading?: string | React.ReactNode;
    formatType?: FormatType;
    background?: string;
    ratio: number;
    trendKey?: string;
}
export interface ISummaryDataGrid {
    header: ISummaryDataGridHeader[];
    data: any[];
    fontClass?: string;
    background?: string
    spacing?: "normal" | "small";

}
function SummaryDataGrid({ header, data, fontClass, background, spacing }: ISummaryDataGrid) {
    const getFormattedData = useCallback((d: ISummaryDataGridHeader, data: any) => {
        switch (d.formatType) {
            case "percentage":
                return <span>{Number(data?.[d.id]).toFixed(2)}%</span>;
            case "number":
                return formatNumberCompact(data?.[d.id]);
            case "trend":
                return (
                    <>
                        <span>
                            {Number(data?.[d.id]).toFixed(2)}%
                        </span>
                        {
                            d?.trendKey
                            && <span>
                                <FontAwesomeIcon icon={data[d.trendKey] > 0 ? faUpLong : faDownLong} />
                            </span>
                        }
                    </>
                );
            case "currency":
                return (
                    <span>{formatCurrencyForInsight(data?.[d.id])}</span>
                );
            default:
                return <span>{data?.[d.id]}</span>;;
        }

    }, []);

    return (
        <div className={"grid-wrapper"}>
            <div className={`${styles["sdg-cont"]} ${spacing === "small" ? styles["sdg-small"] : ""}`}>
                <div className={`row ${styles["sdg-header"]}`}>
                    {
                        header.map(i => <div id={i.id} className={`${styles["sdg-header-item"]} ${styles[`sdg-header-item-${fontClass}`]} col-${i.ratio} col-md-${i.ratio} `}>
                            {i.heading}
                            {i.subHeading
                                && <span className={styles["sdg-header-item-sub-heading"]}>{i.subHeading}</span>
                            }
                        </div>)
                    }
                </div>
                <div className={styles["sdg-row"]}>
                    {
                        data.map((i, key) => (
                            <div className={`${styles["sdg-row-item"]} row`}
                                style={{
                                    background: key === 0 ? `${background}` : "",
                                    color: key === 0 && background ? "#fff" : "",
                                    borderColor: key === 0 ? `${background}` : "",
                                }}>
                                {
                                    header.map(j => (
                                        <div
                                            className={`${styles["sdg-row-item-elem"]} ${key == 0 && background ? styles["sdg-row-item-elem-background"] : ""} col-${j.ratio} col-md-${j.ratio}`}>
                                            {getFormattedData(j, i)}
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default SummaryDataGrid;
