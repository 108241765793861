import React from "react";

import { faUser } from "@fortawesome/free-solid-svg-icons";

import styles from "@/assets/styles/pages/home.module.scss";
import { MainPageTabs } from "@/enum/common";
import CommonBarChart from "@/shared/components/charts/CommonBarChart";
import SectionTitle from "@/shared/components/section-title/SectionTitle";

interface IGenerationShift {
    data: Homepage.GenerationalShift;
}
function GenerationShift({ data }: IGenerationShift) {
    const summary = data?.["section4.1"];
    const chartData = data?.["section4.2"];
    return (
        <div className={styles["generation-shift"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SectionTitle
                                iconProps={{
                                    icon: faUser,
                                    background: "#00A7B7",
                                }}
                                title={"Generational shift through gender"}
                                summary={summary}
                                explore
                                exploreTab={MainPageTabs.generations}
                            />
                        </div>
                        <div className={`col-12 col-xl-6 ${styles["generation-shift-chart"]}`}>
                            <CommonBarChart
                                settings={{
                                    tooltipFormatType: "percent",
                                    title: {
                                        text: "Active UK company generation analysis",
                                    },
                                    height: 345,
                                    colors: ["#FD7278", "#00A7B7", "#656394"],
                                    legend: {
                                        position: "bottom",
                                    },
                                    plotOptions: {
                                        bar: {
                                            barHeight: "70%",
                                            horizontal: true,
                                            // dataLabels: {
                                            //     position: "top",
                                            //     hideOverflowingLabels: false,
                                            // },
                                        },
                                    },
                                    chart: {
                                        type: "bar",
                                        stacked: true,
                                        stackType: "100%",
                                        zoom: {
                                            enabled: false,
                                        },
                                        toolbar: {
                                            show: false,
                                        },
                                    },
                                    xaxis: {
                                        axisTicks: {
                                            show: false,
                                        },
                                        axisBorder: {
                                            show: false,
                                        },
                                        labels: {
                                            show: false,
                                        },
                                    },
                                    yaxis: {
                                        axisTicks: {
                                            show: false,
                                        },
                                        axisBorder: {
                                            show: false,
                                        },
                                    },
                                    grid: {
                                        xaxis: {
                                            lines: {
                                                show: false,
                                            },
                                        },
                                        yaxis: {
                                            lines: {
                                                show: false,
                                            },
                                        },
                                    },
                                    dataLabels: {
                                        enabled: false,
                                        // textAnchor: "middle",
                                        // offsetX: 18,
                                        // style: {
                                        //     fontWeight: "bold",
                                        // },
                                        // formatter(val, opts) {
                                        //     return getFlooredFixed(opts.globals.series[opts.seriesIndex][opts.dataPointIndex], 1) + "%";
                                        // },
                                    },
                                    // responsive: [
                                    //     {
                                    //         breakpoint: 768,
                                    //         options: {
                                    //             dataLabels: {
                                    //                 dataLabels: 18,
                                    //             },
                                    //         },
                                    //     },
                                    // ],
                                }}
                                chartData={chartData}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default GenerationShift;
