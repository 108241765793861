import React from "react";

import dynamic from "next/dynamic";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface IApexChart {
    type?: "line" | "area" | "bar" | "histogram" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "treemap" | "boxPlot" | "candlestick" | "radar" | "polarArea" | "rangeBar",
    series?: Array<any>,
    options?: any,
    width?: string | number,
    height?: string | number,
}

function ApexChart({ type, series, options, width, height }: IApexChart) {
    return (
        <>
            <Chart
                type={type as any}
                series={series}
                options={options}
                width={width}
                height={height}
            />
        </>
    );
}

export default ApexChart;
