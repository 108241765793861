import React, { useCallback } from "react";

import { commaFormattedCurrency, formatAsPercentage, formatNumberCompact } from "@/helpers/common";
import ApexChart from "@/shared/components/charts/ApexChart";

import styles from "../../../assets/styles/components/chart.module.scss";
function CommonBarChart({ chartData, settings }: Chart.IBarChart) {
    // console.log("chartData:-", chartData);
    const title = settings?.title?.text;
    const getChart = useCallback(() => {
        const defaultOptions: ApexCharts.ApexOptions = {
            chart: {
                type: "bar",
                id: "barchart",
                stacked: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            // dataLabels: {
            //     enabled: false,
            //     formatter: function (val) {
            //         if (isNaN(+val)) return val as any;

            //         return commaSeperatorFormat(String(val));
            //     },
            // },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    horizontal: true,
                },
            },
            colors: settings?.textChartColor,
            legend: {
                show: false,
            },
            grid: {
                show: true,
                borderColor: "#282D32",
                row: {
                    colors: undefined,
                    opacity: 0.4,
                },
                column: {
                    colors: undefined,
                    opacity: 0.4,
                },
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [],
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                    },
                },
                title: {
                    offsetX: 10,
                    offsetY: 0,
                    style: {
                        color: "#282D32",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        cssClass: "apexcharts-yaxis-title",
                    },
                },
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                title: {
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "#282D32",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        cssClass: "apexcharts-yaxis-title",
                    },
                },
            },
            tooltip: {
                y: {
                    formatter(val) {
                        if (isNaN(+val)) return val as any;
                        switch (settings?.tooltipFormatType) {
                            case "currency":
                                return commaFormattedCurrency(val.toString());
                            case "percent":
                                return formatAsPercentage(+val.toFixed(2));
                            default:
                                return formatNumberCompact(val);
                        }

                    },
                },
            },

        };
        const options = {
            ...defaultOptions,
            ...settings,
            title: {
                text: undefined,
            },
        };

        const data = chartData?.data;
        const xAxis = chartData?.xAxis;
        let defaultSeries: Array<Chart.IBarData> = [];
        if (data && data.length) {
            defaultSeries = data.map((value: Chart.IBarData) => value);
        }

        if (options.xaxis && xAxis?.categories) {
            options.xaxis.categories = xAxis.categories;
            if (settings?.allowLabelLineBreak) {
                const a: string[][] = [];
                xAxis.categories.forEach((i: string) => {
                    a.push(i.split(" "));
                });
                options.xaxis.categories = a;
            }
        }
        return (
            <ApexChart
                type='bar'
                series={defaultSeries}
                options={options}
                height={settings?.height}
            />
        );
    }, [chartData, settings]);
    return (
        <div className={styles.cont}>
            <div className={styles["cont-title"]}>{title}</div>
            <div>
                {getChart()}
            </div>
        </div>
    );
}

export default CommonBarChart;
