import React from "react";

import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";

import styles from "@/assets/styles/pages/home.module.scss";
import { formatAsPercentage, formatNumberCompact } from "@/helpers/common";

interface IUkStorySection {
    data: Homepage.UkStory;
}

function UkStorySection({ data }: IUkStorySection) {

    const ukStoryData = [
        {
            label: "Active Female-led companies",
            number: data?.["section1.1"].count ? formatNumberCompact(data?.["section1.1"].count) : 0,
            perc: Number(data?.["section1.1"].perc.toFixed(1)),
            subLabel: "of total active companies",
        },
        {
            label: "Female-led incorporations in the last 12 months",
            number: data?.["section1.2"].count ? formatNumberCompact(data?.["section1.2"].count) : 0,
            perc: Number(data?.["section1.2"].perc.toFixed(1)),
            subLabel: "from previous 12 months",
            icon: true,
        },
        {
            label: "Investments received by female-led companies in the last 12 months",
            number: data?.["section1.3"].count ? formatNumberCompact(data?.["section1.3"].count as any) : 0,
            perc: Number(data?.["section1.3"].perc.toFixed(1)),
            subLabel: "of all investments",
        },
    ];
    return (
        <div className={styles["uk-story"]}>
            <div className="grid-wrapper">
                <div className={styles["uk-story-left"]}>
                    <Image
                        src="/images/uk_story-left.png"
                        alt="home image"
                        width={590}
                        height={300}
                        loading="lazy"
                    />
                </div>
                <div className={`${styles["uk-story-text-overlay"]} row`}>
                    <div className="container">
                        <div className={styles["uk-story-title"]}>
                            The UK story so far
                        </div>

                        {
                            ukStoryData.map(i => (
                                <div className={`${styles["uk-story-row"]} row`} key={i.label}>
                                    <div className={`${styles["uk-story-left-stat"]} col-12 col-lg-7`}>
                                        <div className={styles["uk-story-left-stat-box"]}>
                                            <div className={styles["uk-story-left-stat-figure"]}>{i.number}</div>
                                            <div className={styles["uk-story-left-stat-text"]}>
                                                {i.label}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles["uk-story-right-stat"]} col-12 col-lg-5`}>
                                        <div className={styles["uk-story-right-stat-box"]} >
                                            <div className={styles["uk-story-right-stat-figure"]}>
                                                {i?.icon && i.perc !== 0 ? <FontAwesomeIcon icon={i.perc > 0 ? faUpLong : faDownLong} /> : ""}
                                                {formatAsPercentage(i.perc).replace(/-(?=\d)/, "")}
                                            </div>
                                            <div className={styles["uk-story-right-stat-text"]}>
                                                {i.subLabel}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles["uk-story-right"]}>
                    <Image
                        src="/images/uk-story-right.png"
                        alt="home image"
                        width={300}
                        height={500}
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    );
}

export default UkStorySection;

