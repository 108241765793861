import React from "react";

interface IButton {
    children: any;
    disabled?: boolean;
    variant?: "outlined"
    color?: "green" | "pink" | "blue" | "red";
    size?: "small" | "large";
}
function Button({ disabled, children, color = "blue", variant }: IButton) {
    return (
        <button
            className={`btn-${variant === "outlined" ? `${color}-${variant}` : color} btn-reset`}
            type="submit"
            disabled={disabled}>{children}</button>
    );
}

export default Button;
