import React, { useCallback } from "react";

import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";

import styles from "@/assets/styles/pages/home.module.scss";
import { formatAsPercentage } from "@/helpers/common";
import { secureDebtData } from "@/helpers/filter-data/growth";
import { FILTER_KEYS, getInitialMainFilter } from "@/helpers/filters";
import { useFilters } from "@/providers/FiltersProvider";
import CircleWithText, { ICircleData } from "@/shared/components/CircleWithText";
import SectionTitle from "@/shared/components/section-title/SectionTitle";

interface ISecuredDebt {
    data: Homepage.SecureDebt
}

function SecuredDebt({ data }: ISecuredDebt) {
    const summary = data?.["section9.1"];
    const _secureDebtData = data?.["section9.2"];
    const { setRedirectFilters } = useFilters();

    const onClick = useCallback(() => {
        setRedirectFilters(
            {
                ...getInitialMainFilter(),
                [FILTER_KEYS["Secured debt"]]:
                {
                    data: [secureDebtData[0]],
                },
            }
        );
    }, [setRedirectFilters]);

    const activeCompaniesData: ICircleData[] = [
        {
            name: _secureDebtData?.["Female-led"].category,
            value: formatAsPercentage(_secureDebtData?.["Female-led"].perc.toFixed(1) as any),
            background: "#FD7278",
        },
        {
            name: _secureDebtData?.["Male-led"].category,
            value: formatAsPercentage(_secureDebtData?.["Male-led"].perc.toFixed(1) as any),
            background: "#00A7B7",
        },
        {
            name: _secureDebtData?.Other.category,
            value: formatAsPercentage(_secureDebtData?.Other.perc.toFixed(1) as any),
            background: "#656394",
        },
    ];

    return (
        <div className={styles["secured-debt"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SectionTitle
                                iconProps={{
                                    icon: faBuildingColumns,
                                    background: "#FF006F",
                                }}
                                title={"Secured debt gender analysis"}
                                summary={summary}
                                explore
                                cb={onClick}
                            />
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className={styles["secured-debt-right"]}>
                                <CircleWithText
                                    data={activeCompaniesData}
                                    title="Percentage of active companies with secured debt" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SecuredDebt;
