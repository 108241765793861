import React from "react";

import { faBookOpenReader, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import { TinaMarkdown } from "tinacms/dist/rich-text";

import styles from "@/assets/styles/pages/home.module.scss";
import { APP_ROUTES } from "@/constants/routes";
import Button from "@/shared/components/Button";

function HeroSection({ mainData, data }: any) {

    const content = mainData?.data?.page?.body;
    let subContent = mainData?.data?.page?.subBody;

    // Replace the percentage in the subContent
    const percentageFemaleLed = (Number(data?.["section1.1"].perc.toFixed(1)) || 20) + "%";
    subContent = JSON.stringify(subContent);
    subContent = subContent.replace("{{percentageFemaleLed}}", percentageFemaleLed) ;
    subContent = JSON.parse(subContent);

    return (
        <div className={styles["hero-section"]}>
            {/* <Header /> */}
            <div className={styles["hero-section-cont"]}>

                <div className={styles["hero-section-left"]}>
                    <TinaMarkdown content={content} />
                    <div className={styles["hero-section-divider-red"]}></div>
                    <TinaMarkdown content={subContent} />
                    <div className='actionBtnsWrap'>
                        <Link href={APP_ROUTES.explore}>
                            <Button
                                variant="outlined" color="red">
                                <FontAwesomeIcon icon={faDatabase} className="redColor" /> Explore the data
                            </Button>
                        </Link>
                        <Link href="/reports">
                            <Button
                                variant="outlined" color="green">
                                <FontAwesomeIcon icon={faBookOpenReader} /> Read latest report
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={styles["hero-section-right"]}>
                    <Image
                        // src="/images/home_section1.png"
                        src={"/images/hero-image.png"}
                        alt="home image"
                        width={600}
                        height={600}
                        priority
                        sizes="(max-width: 480px) 80vw, (max-width: 1200px) 50vw"
                    />
                </div>
            </div>
        </div>
    );
}

export default HeroSection;

